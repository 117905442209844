body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.riotbar-present {
  margin: 0 !important;
  overflow: unset !important;
}

.riotbar-root {
  display: none;
}

.riotbar-footer-logo * svg {
  margin-bottom: 8px;
}

#footer {
  position: relative;
  z-index: 30;
}
