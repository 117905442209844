@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/static/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #0a0a0a;
  font-family: Inter, Helvetica, Arial, sans-serif !important;
}

button,
.MuiButton-label {
  font-family: Inter, Helvetica, Arial, sans-serif !important;
}

.sidebarLink.Mui-focusVisible
  .MuiListItem-root.MuiListItem-gutters
  .MuiTypography-root {
  color: #fcfcfc !important;
}

.sidebarLink.Mui-focusVisible
  .MuiListItem-root.MuiListItem-gutters
  .MuiListItemIcon-root
  path {
  fill: #d53235 !important;
}

.sidebarLink.Mui-focusVisible
  .MuiListItem-root.MuiListItem-gutters
  .MuiListItemButton-root
  > svg {
  color: #fcfcfc !important;
}

.sidebarLink.Mui-focusVisible {
  outline: none;
}

.sidebarLink {
  text-decoration: none !important;
}

.sidebarLink:focus-visible
  .MuiListItem-root.MuiListItem-gutters
  .MuiTypography-root {
  color: #fcfcfc !important;
}

.sidebarLink:focus-visible
  .MuiListItem-root.MuiListItem-gutters
  .MuiListItemIcon-root
  path {
  fill: #d53235 !important;
}

.sidebarLink:focus-visible
  .MuiListItem-root.MuiListItem-gutters
  .MuiListItemButton-root
  > svg {
  color: #fcfcfc !important;
}

.sidebarLink:focus-visible {
  outline: none;
}
